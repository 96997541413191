import { React, Helmet } from '@/vendor'
import { Mq, Craft, ScrollToTop, PageChangeListener, AppLoading } from '@/components'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { get } from 'lodash'

// Routes
import PageNotFoundRoute from '@/routes/404'
import HomepageRoute from '@/routes/homepage'
import WhoWeAreRoute from '@/routes/who-we-are'
import ServicesRoute from '@/routes/services'
import ServicesSlugRoute from '@/routes/services/:slug'
import CheesesRoute from '@/routes/cheeses'
import ResourcesRoute from '@/routes/resources'
import ResourcesSlugRoute from '@/routes/resources/:slug'
import CareersRoute from '@/routes/careers'
import ContactRoute from '@/routes/contact'
import HowToBuyRoute from '@/routes/how-to-buy'
import TransparencyAct from '@/routes/transparency-act'
import { GoogleAnalyticsPageView, GoogleAnalyticsProvider } from './components/GoogleAnalytics'

const CRAFT_URL = `https://pacificcheese.craftcms.me/api`

function flattenImage(arr, keys) {
	return arr.map((item) => {
		for (const key of [keys].flat()) {
			item[key] = get(item, `${key}[0].url`)
		}
		return item
	})
}

function serializeCraft(data) {
	data.services = data.services.map((service) => {
		service.logos = service.logos.map((logo) => logo.id)
		service.body = flattenImage(service.body, 'image')
		return service
	})
	data.offices = flattenImage(data.offices, 'leadImage')
	data.cheeses = flattenImage(data.cheeses, ['leadImage', 'iconImage'])
	data.resources = flattenImage(data.resources, 'leadImage')
	data.historyTimelime = flattenImage(data.historyTimelime, 'leadImage')
	data.resources = data.resources.map((resource) => {
		resource.category = resource.category[0]
		return resource
	})
	return data
}

class App extends React.PureComponent {
	state = {
		overlayNavActive: false
	}

	get breakpoints() {
		return {
			small: 'screen and (max-width: 767px)',
			medium: 'screen and (min-width: 768px) and (max-width: 999px)',
			large: 'screen and (min-width: 1000px)'
		}
	}

	render() {
		return (
			<GoogleAnalyticsProvider ua="G-ER0LYMSPST">
				<Craft.Provider apiUrl={CRAFT_URL} query={require('./craft.gql')} serialize={serializeCraft}>
					{({ loading }) => (
						<Mq.Provider config={this.breakpoints}>
							<BrowserRouter>
								<GoogleAnalyticsPageView />
								<PageChangeListener
									callback={() => {
										this.setState({
											overlayNavActive: false
										})
									}}
								/>
								<ScrollToTop />
								<Helmet titleTemplate="%s | Pacific Cheese" />
								{loading ? (
									<AppLoading />
								) : (
									<Routes>
										<Route path="*" element={<PageNotFoundRoute />} />
										<Route path="/" element={<HomepageRoute />} />
										<Route path="/who-we-are" element={<WhoWeAreRoute />} />
										<Route path="/services/" element={<ServicesRoute />} />
										<Route path="/services/:slug" element={<ServicesSlugRoute />} />
										<Route path="/cheeses" element={<CheesesRoute />} />
										<Route path="/resources/" element={<ResourcesRoute />} />
										<Route path="/resources/:slug" element={<ResourcesSlugRoute />} />
										<Route path="/careers" element={<CareersRoute />} />
										<Route path="/how-to-buy" element={<HowToBuyRoute />} />
										<Route path="/contact" element={<ContactRoute />} />
										<Route path="/transparency-act" element={<TransparencyAct />} />
									</Routes>
								)}
							</BrowserRouter>
						</Mq.Provider>
					)}
				</Craft.Provider>
			</GoogleAnalyticsProvider>
		)
	}
}

export default App
