import { React, PropTypes, Classnames } from '@/vendor'
import styles from './styles.module.scss'
import { omit } from 'lodash'

class Cell extends React.PureComponent {
	static propTypes = {
		className: PropTypes.string,
		flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	}
	render() {
		const { className, flex, ...props } = this.props
		const classNames = Classnames(className, styles.cell)
		const style = {
			flex: flex === 0 ? '0 0 auto' : flex
		}
		return <div className={classNames} style={style} {...props} />
	}
}

class Grid extends React.PureComponent {
	static Cell = Cell

	static propTypes = {
		flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		stacked: PropTypes.bool,
		grow: PropTypes.bool,
		reverse: PropTypes.bool,
		className: PropTypes.string,
		wrap: PropTypes.bool
	}

	get classNames() {
		return Classnames(styles.grid, this.props.className, {
			[styles.stacked]: this.props.stacked,
			[styles.reverse]: this.props.reverse,
			[styles.wrap]: this.props.wrap
		})
	}

	get style() {
		const style = {}
		if (this.props.flex !== undefined) {
			const flex = this.props.flex
			style.flex = flex === 0 ? '0 0 auto' : flex
		}
		if (this.props.grow === false) {
			style.flexGrow = 0
		}
		return style
	}

	get passThroughProps() {
		const localProps = Object.keys(Grid.propTypes)
		return omit(this.props, localProps)
	}

	render() {
		return (
			<div
				className={this.classNames}
				style={this.style}
				{...this.passThroughProps}
				ref={(el) => {
					this.$el = el
				}}
			/>
		)
	}
}

export default Grid
