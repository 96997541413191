import { React } from '@/vendor'

function SvgNotch(props) {
	return (
		<svg width={8} height={12} viewBox="0 0 8 12" {...props}>
			<path d="M.693.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 11-1.414-1.414L4.985 6 .693 1.707A1 1 0 01.61.387z" />
		</svg>
	)
}

export default SvgNotch
