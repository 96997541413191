import React from 'react'
import PropTypes from 'prop-types'
import loadScript from '@/lib/loadScript'
import { useLocation } from 'react-router-dom'

function gtag() {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push(arguments)
}

async function initGA(ua) {
	await loadScript(`https://www.googletagmanager.com/gtag/js?id=${ua}`)
	gtag('js', new Date())
	gtag('config', ua, {
		send_page_view: false
	})
}

const Context = React.createContext(null)

export function GoogleAnalyticsProvider({ ua, ...props }) {
	React.useEffect(() => {
		if (ua) {
			initGA(ua)
		}
	}, [ua])
	return (
		<Context.Provider
			value={{
				sendPageView: (pathname) => {
					gtag('event', 'page_view', {
						page_path: pathname
					})
				}
			}}
			{...props}
		/>
	)
}

GoogleAnalyticsProvider.propTypes = {
	ua: PropTypes.string.isRequired
}

export function useGoogleAnalytics() {
	return React.useContext(Context)
}

export function GoogleAnalyticsPageView() {
	const { sendPageView } = useGoogleAnalytics()
	const { pathname } = useLocation()
	React.useEffect(() => {
		if (pathname) {
			sendPageView(pathname)
		}
	}, [pathname])
	return null
}
