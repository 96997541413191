import { React, PropTypes, Classnames, Helmet } from '@/vendor'
import { Craft, AppLayout, Mq, Grid, Intro, Button, Fs, Img, SimpleImgText, FourColWrap, RichText } from '@/components'
import styles from './styles.module.scss'

function ValueProp({ image, headline, copy }) {
	return (
		<div className={styles.valueProp}>
			<Img src={image} className={styles.image} />
			<Fs tag="h3" variant="fs3">
				{headline}
			</Fs>
			<Fs variant="body">{copy}</Fs>
		</div>
	)
}
ValueProp.propTypes = {
	image: PropTypes.string,
	headline: PropTypes.node,
	copy: PropTypes.node
}

function WhoWeAreRoute() {
	return (
		<>
			<Helmet>
				<title>Who we are</title>
			</Helmet>
			<AppLayout
				masthead={
					<AppLayout.Masthead
						background="https://d3bcmafqb1amu0.cloudfront.net/mastheads/who-we-are@2x.jpg"
						title="Who We Are"
						headline="Over 50 years of industry leadership, innovation, and excellence."
					/>
				}
				body={
					<Craft>
						{({ historyTimelime }) => (
							<Mq>
								{(Mq) => {
									return (
										<div className={styles.layout}>
											<Grid>
												<Grid.Cell>
													<Intro
														headline="We are Pacific Cheese"
														html={
															<p>
																In 1970 Ray and June Gaddis established Pacific Cheese
																Company, the West Coast’s first broadline cheese
																supplier. As industry pioneers, they were instrumental
																in the expansion of the California cheese industry and
																laid the groundwork for the company’s growth into one of
																the country’s leading suppliers of premier-quality,
																natural cheeses. Today, a second generation of the
																Gaddis family oversees the company’s success and
																industry leadership.
															</p>
														}
													/>
												</Grid.Cell>
											</Grid>
											<div className={styles.layout__section}>
												<FourColWrap>
													<ValueProp
														image="https://d3bcmafqb1amu0.cloudfront.net/who-we-are/our_purpose.png"
														headline="Our Purpose"
														copy="We exist to help inspire moments that bring families together over amazing recipes and cheeses."
													/>
													<ValueProp
														image="https://d3bcmafqb1amu0.cloudfront.net/who-we-are/our_cheeses.png"
														headline="Our Cheeses"
														copy="We strive to make every cheese with the best quality, style, and craftsmanship that honor our heritage."
													/>
													<ValueProp
														image="https://d3bcmafqb1amu0.cloudfront.net/who-we-are/our_promise.png"
														headline="Our Promise"
														copy="We exceed food safety guidelines and ensure our process will always meet the industry’s expectations."
													/>
													<ValueProp
														image="https://d3bcmafqb1amu0.cloudfront.net/who-we-are/our_partners.png"
														headline="Our Partners"
														copy="We stay connected with our partners because it matters that you have what you need when you need it."
													/>
												</FourColWrap>
											</div>
											<div className={`${styles.layout__section} ${styles.history}`}>
												<div className={styles.history__headline}>
													<Fs tag="h2" variant="fs6">
														Our History
													</Fs>
												</div>
												<SimpleImgText alternate>
													{historyTimelime.map((timeline) => (
														<SimpleImgText.Row
															key={timeline.id}
															image={<Img src={timeline.leadImage} />}
															text={
																<SimpleImgText.Text
																	headline={timeline.title}
																	body={<RichText>{timeline.body}</RichText>}
																/>
															}
														/>
													))}
												</SimpleImgText>
											</div>
											<div className={styles.layout__section}>
												<Grid>
													<Grid.Cell>
														<div
															className={Classnames(styles.quote, {
																[styles.fancy]: !Mq.small
															})}
														>
															<div className={styles.inner}>
																<Fs
																	tag="h2"
																	variant="fs6"
																	className={styles.quote__headline}
																>
																	To A Bright Future
																</Fs>
																<Fs variant="fs3">
																	My father started this company as a small cheese
																	brokerage with just five employees and a passion for
																	cheese. As California’s dairy industry grew, so did
																	we. Today, Pacific Cheese is proud to be the leading
																	supplier of premium-quality natural cheese. We’re
																	looking forward to a very bright future.”
																</Fs>
																<div className={styles.quote__cite}>
																	<Fs variant="body">
																		Steve Gaddis, President & CEO
																	</Fs>
																</div>
															</div>
														</div>
													</Grid.Cell>
												</Grid>
											</div>
										</div>
									)
								}}
							</Mq>
						)}
					</Craft>
				}
			/>
		</>
	)
}

export default WhoWeAreRoute
