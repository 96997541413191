import { React, PropTypes, Classnames } from '@/vendor'
import videojs from 'video.js'

import 'video.js/dist/video-js.css'
import './video-js-theme.scss'

class VideoPlayer extends React.PureComponent {
	componentDidMount() {
		this.player = videojs(this.el, {
			inactivityTimeout: 0,
			responsive: true,
			fluid: true
		})
	}

	componentWillUnmount() {
		if (this.player && this.player.dispose) {
			this.player.dispose()
		}
	}

	render() {
		const { className, ...props } = this.props
		return (
			<video
				className={Classnames('video-js', className)}
				preload="auto"
				ref={(el) => {
					this.el = el
				}}
				{...props}
			/>
		)
	}
}

export default VideoPlayer
