import { React, PropTypes } from '@/vendor'
import Grid from '../Grid'
import styles from './styles.module.scss'

function FourColWrap({ children }) {
	return (
		<Grid className={styles.fourColWrap} wrap>
			{React.Children.map(children, (child, idx) => (
				<Grid.Cell key={idx}>{child}</Grid.Cell>
			))}
		</Grid>
	)
}

FourColWrap.propTypes = {
	children: PropTypes.array
}

export default FourColWrap
