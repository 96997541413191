import loadScriptCallback from 'load-script'

async function loadScript(url, opts = {}) {
	return new Promise((resolve, reject) => {
		loadScriptCallback(url, opts, (err, script) => {
			if (err) {
				reject(err)
			} else {
				resolve(script)
			}
		})
	})
}

export default loadScript
