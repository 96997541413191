import { React } from '@/vendor'
import Grid from '../Grid'
import styles from './styles.module.scss'

function HorzRule() {
	return (
		<Grid>
			<Grid.Cell>
				<div className={styles.horzRule} />
			</Grid.Cell>
		</Grid>
	)
}

export default HorzRule
