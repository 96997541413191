import { React, Helmet } from '@/vendor'
import { Craft, AppLayout, Grid, Fs } from '@/components'
import { InlineNav, InlineNavItem } from '@/components/InlineNav'
import styles from './styles.module.scss'
import { useMq } from '../../components/Mq'

function filterByCategoryId(needle, haystack) {
	return haystack.filter((contact) => contact.salesCategory.some((category) => category.id === needle))
}

function HowToBuyRoute() {
	const [categoryId, setCategoryId] = React.useState(null)
	const mq = useMq()
	return (
		<Craft>
			{({ salesContacts, salesCategories }) => {
				const filteredResults = categoryId ? filterByCategoryId(categoryId, salesContacts) : salesContacts
				return (
					<>
						<Helmet>
							<title>How to Buy</title>
						</Helmet>
						<AppLayout
							masthead={
								<AppLayout.Masthead
									background="https://d3bcmafqb1amu0.cloudfront.net/mastheads/how_to_buy@2x.jpg"
									title="How to Buy"
									headline="Contact Sales"
									body={<p>Contact our sales representatives or customer service at 800.499.6200</p>}
								/>
							}
							body={
								<>
									{!mq.small && (
										<InlineNav
											initial={null}
											onChange={(categoryId) => {
												setCategoryId(categoryId)
											}}
										>
											<InlineNavItem label="All" value={null} />
											{salesCategories.map((category) => (
												<InlineNavItem
													key={category.id}
													value={category.id}
													label={category.title}
												/>
											))}
										</InlineNav>
									)}
									<Grid className={styles.listings} wrap>
										{filteredResults.map((result) => (
											<Grid.Cell key={result.id} className={styles.listings__cell}>
												<Fs align="center" className={styles.card}>
													<Fs variant="fs3">{result.title}</Fs>
													<Fs variant="caption">{result.jobTitle}</Fs>
													<div>{result.phoneNumber}</div>
													<div>
														<a href={`mailto:${result.email}`}>{result.email}</a>
													</div>
												</Fs>
											</Grid.Cell>
										))}
									</Grid>
								</>
							}
						/>
					</>
				)
			}}
		</Craft>
	)
}

export default HowToBuyRoute
