import { React, Helmet } from '@/vendor'
import { Craft, AppLayout, SimpleImgText, Img, Nl2br, Fs } from '@/components'

function ContactRoute() {
	return (
		<Craft>
			{({ offices }) => (
				<>
					<Helmet>
						<title>Contact</title>
					</Helmet>
					<AppLayout
						masthead={
							<AppLayout.Masthead
								background="https://d3bcmafqb1amu0.cloudfront.net/mastheads/contact@2x.jpg"
								title="Contact"
								headline="Get in Touch"
							/>
						}
						body={
							<SimpleImgText>
								{offices.map((office) => {
									const mapsLink =
										office.googleMapsLink ||
										`https://maps.google.com/?q=${office.address.replace(/\n/g, ' ')}`
									return (
										<SimpleImgText.Row
											key={office.id}
											image={
												<a href={mapsLink} target="_blank">
													<Img src={office.leadImage} />
												</a>
											}
											text={
												<SimpleImgText.Text
													headline={office.title}
													body={
														<Fs>
															<p>
																<Nl2br text={office.address} />
																<a href={mapsLink} target="_blank">
																	Driving Directions
																</a>
															</p>
															<p>
																Tel: {office.phoneNumber}
																<br />
																{office.faxNumber && (
																	<>
																		Fax: {office.faxNumber}
																		<br />
																	</>
																)}
																Email:{' '}
																<a href="mailto:info@pacificcheese.com">
																	info@pacificcheese.com
																</a>
															</p>
														</Fs>
													}
												/>
											}
										/>
									)
								})}
							</SimpleImgText>
						}
					/>
				</>
			)}
		</Craft>
	)
}

export default ContactRoute
