import { React, PropTypes, Helmet, Link } from '@/vendor'
import { AppLayout, Craft, Grid, Fs, Img, SvgNotch } from '@/components'
import styles from './styles.module.scss'
import { InlineNav, InlineNavItem } from '@/components/InlineNav'

function Listing({ leadImage, category, title, slug }) {
	return (
		<Link to={`/resources/${slug}`} className={styles.listing}>
			<div className={styles.listing__image}>
				<Img src={leadImage} />
			</div>
			<div className={styles.listing__inner}>
				<div className={styles.listing__label}>
					<div>{category.title}</div>
				</div>
				<Fs variant="fs4" className={styles.listing__title}>
					{title}
				</Fs>
				<Fs variant="fs6" className={styles.listing__cta}>
					Read Post <SvgNotch />
				</Fs>
			</div>
		</Link>
	)
}

Listing.propTypes = {
	leadImage: PropTypes.string,
	category: PropTypes.shape({
		title: PropTypes.string
	}),
	title: PropTypes.string,
	slug: PropTypes.string
}

class ResourcesRoute extends React.PureComponent {
	state = {
		categoryId: null
	}

	filteredResources = (resources) => {
		if (this.state.categoryId) {
			return resources.filter((resource) => resource.category.id === this.state.categoryId)
		}
		return resources
	}

	render() {
		return (
			<Craft>
				{({ resourceCategories, resources }) => (
					<>
						<Helmet>
							<title>Resources</title>
						</Helmet>
						<AppLayout
							masthead={<AppLayout.Masthead title="Resources" headline="News and Insights" />}
							bodyClassName={styles.body}
							body={
								<>
									<InlineNav
										initial={null}
										onChange={(categoryId) => {
											this.setState({ categoryId })
										}}
									>
										<InlineNavItem label="All" value={null} />
										{resourceCategories.map((category) => (
											<InlineNavItem
												key={category.id}
												value={category.id}
												label={category.title}
											/>
										))}
									</InlineNav>
									<Grid className={styles.listings} wrap>
										{this.filteredResources(resources).map((resource) => (
											<Grid.Cell key={resource.id} className={styles.listings__cell}>
												<Listing {...resource} />
											</Grid.Cell>
										))}
									</Grid>
								</>
							}
						/>
					</>
				)}
			</Craft>
		)
	}
}

export default ResourcesRoute
