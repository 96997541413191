import { React, PropTypes, Classnames } from '@/vendor'
import Fs from '../Fs'
import styles from './styles.module.scss'

function Intro({ className, preHeadline, headline, html, children, ...props }) {
	const setHtml = typeof html === 'string'
	return (
		<div className={Classnames(styles.intro, className)} {...props}>
			{preHeadline && (
				<Fs variant="fs6" className={styles.preHeadline}>
					{preHeadline}
				</Fs>
			)}
			{headline && (
				<Fs tag="h2" variant="fs2">
					{headline}
				</Fs>
			)}
			{setHtml ? (
				<Fs variant="intro" dangerouslySetInnerHTML={{ __html: html }} />
			) : (
				<Fs variant="intro">{html}</Fs>
			)}
			{children}
		</div>
	)
}

Intro.propTypes = {
	className: PropTypes.string,
	preHeadline: PropTypes.node,
	headline: PropTypes.node,
	html: PropTypes.node,
	children: PropTypes.node
}

export default Intro
