import { React, PropTypes } from '@/vendor'
import ApolloClient from 'apollo-boost'

const Context = React.createContext()

class Provider extends React.PureComponent {
	static defaultProps = {
		serialize: (data) => data
	}

	static propTypes = {
		apiUrl: PropTypes.string.isRequired,
		query: PropTypes.object.isRequired,
		serialize: PropTypes.func,
		children: PropTypes.func
	}

	state = {
		loading: true
	}

	constructor(props) {
		super(props)
		this.$client = new ApolloClient({
			uri: props.apiUrl
		})
	}

	async componentDidMount() {
		const response = await this.$client.query({
			query: this.props.query
		})
		const data = this.props.serialize(response.data)
		this.setState({
			loading: false,
			...data
		})
	}

	render() {
		return <Context.Provider value={this.state} children={this.props.children(this.state)} />
	}
}

class Craft extends React.PureComponent {
	static Provider = Provider

	render() {
		return <Context.Consumer {...this.props} />
	}
}

export default Craft
