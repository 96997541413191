import { React, PropTypes } from '@/vendor'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import styles from './styles.module.scss'

class OverlayNav extends React.PureComponent {
	static propTypes = {
		children: PropTypes.arrayOf(PropTypes.element)
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		disableBodyScroll(this.$el)
	}

	componentWillUnmount() {
		enableBodyScroll(this.$el)
	}

	render() {
		return (
			<div
				className={styles.overlayNav}
				ref={(el) => {
					this.$el = el
				}}
			>
				<div className={styles.inner}>
					<ul className={styles.nav}>
						{React.Children.map(this.props.children, (child, idx) => (
							<li key={idx}>{child}</li>
						))}
					</ul>
				</div>
			</div>
		)
	}
}

export default OverlayNav
