import { React } from '@/vendor'
import { Img, NavLink } from '@/components'

export const NavItems = {
	WhoWeAre: () => <NavLink to="/who-we-are">Who We Are</NavLink>,
	Services: () => <NavLink to="/services">Services</NavLink>,
	Cheeses: () => <NavLink to="/cheeses">Cheeses</NavLink>,
	Resources: () => <NavLink to="/resources">Resources</NavLink>,
	Careers: () => <NavLink to="/careers">Careers</NavLink>,
	HowToBuy: () => <NavLink to="/how-to-buy">How to Buy</NavLink>,
	Contact: () => <NavLink to="/contact">Contact</NavLink>
}

export function Logo(props) {
	return (
		<NavLink to="/">
			<Img src={require('./logo@2x.png')} alt="Pacific Cheese" {...props} />
		</NavLink>
	)
}
