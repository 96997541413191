import { React, PropTypes, Helmet, Link } from '@/vendor'
import {
	Craft,
	Mq,
	AppLayout,
	Grid,
	LogoBar,
	SimpleImgText,
	Img,
	Button,
	Intro,
	Fs,
	CSSTransition,
	SvgNotch
} from '@/components'
import { useParams, Navigate } from 'react-router-dom'
import { omit } from 'lodash'
import styles from './styles.module.scss'

function withObserver(Component) {
	return class extends React.PureComponent {
		static propTypes = {
			onObserved: PropTypes.func
		}

		componentDidMount() {
			this.observer = new IntersectionObserver(this.observerHandler)
			this.observer.observe(this.$el)
		}

		componentWillUnmount() {
			this.observer.disconnect()
		}

		observerHandler = ([{ intersectionRatio }]) => {
			this.props.onObserved(intersectionRatio)
		}

		render() {
			const props = omit(this.props, ['onObserved'])
			return (
				<div
					ref={(el) => {
						this.$el = el
					}}
				>
					<Component {...props} />
				</div>
			)
		}
	}
}

const ObservedMasthead = withObserver(AppLayout.Masthead)

class ServiceDetail extends React.PureComponent {
	static propTypes = {
		entry: PropTypes.object.isRequired
	}

	state = {
		showHeaderBar: false
	}

	render() {
		const { entry } = this.props
		const { title, intro, mastheadImage, logos, body } = entry
		return (
			<Mq>
				{(Mq) => (
					<>
						<Helmet>
							<title>{title}</title>
						</Helmet>
						<AppLayout
							masthead={
								<ObservedMasthead
									background={mastheadImage[0].url}
									title="Services"
									headline={title}
									onObserved={(intersectionRatio) => {
										this.setState({
											showHeaderBar: intersectionRatio <= 0
										})
									}}
								/>
							}
							body={
								<div className={styles.layout}>
									{Mq.large && (
										<CSSTransition
											in={this.state.showHeaderBar}
											classNames={{
												enter: styles.enter,
												enterActive: styles.enterActive,
												exitActive: styles.exitActive
											}}
											children={
												<div className={styles.headerBar}>
													<Fs variant="fs5" className={styles.headerBar__title}>
														{entry.title}
													</Fs>
													<Link to="/how-to-buy" className={styles.headerBar__cta}>
														How to Buy <SvgNotch />
													</Link>
												</div>
											}
										/>
									)}
									<Grid>
										<Grid.Cell>
											<Intro html={intro}>{!!logos.length && <LogoBar ids={logos} />}</Intro>
										</Grid.Cell>
									</Grid>
									<div className={styles.layout__body}>
										<SimpleImgText alternate>
											{body.map((content) => (
												<SimpleImgText.Row
													key={content.id}
													image={<Img src={content.image} />}
													text={
														<SimpleImgText.Text
															headline={content.headline}
															body={
																<div
																	dangerouslySetInnerHTML={{
																		__html: content.body
																	}}
																></div>
															}
														/>
													}
												/>
											))}
										</SimpleImgText>
									</div>
									<div className={styles.layout__cta}>
										<Grid>
											<Grid.Cell>
												<Button.Link to="/services">View all Services</Button.Link>
											</Grid.Cell>
										</Grid>
									</div>
								</div>
							}
						/>
					</>
				)}
			</Mq>
		)
	}
}

export default () => {
	const { slug } = useParams()
	return (
		<Craft>
			{({ services }) => {
				const service = services.find((s) => s.slug === slug)
				return service ? <ServiceDetail entry={service} /> : <Navigate to="/services" />
			}}
		</Craft>
	)
}
