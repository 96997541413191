import { React, PropTypes } from '@/vendor'

function Nl2br({ text }) {
	return text.split('\n').map((line, idx) => (
		<React.Fragment key={idx}>
			{line}
			<br />
		</React.Fragment>
	))
}

Nl2br.propTypes = {
	text: PropTypes.string.isRequired
}

export default Nl2br
