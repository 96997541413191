import { React, PropTypes } from '@/vendor'
import Craft from '../Craft'
import Img from '../Img'
import styles from './styles.module.scss'
import { get } from 'lodash'

function LogoBar({ ids }) {
	return (
		<Craft>
			{({ logos }) => {
				const outputLogos = ids.reduce((acc, id) => {
					const logo = logos.find((logo) => `${logo.id}` === `${id}`)
					if (logo) {
						acc.push({
							id: logo.id,
							title: logo.title,
							url: get(logo, 'asset[0].url')
						})
					}
					return acc
				}, [])
				return (
					<div className={styles.wrapper}>
						<ul className={styles.logoBar}>
							{outputLogos.map((logo) => (
								<li key={logo.id}>
									<Img src={logo.url} alt={logo.title} />
								</li>
							))}
						</ul>
					</div>
				)
			}}
		</Craft>
	)
}

LogoBar.propTypes = {
	ids: PropTypes.array
}

export default LogoBar
