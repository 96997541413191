import { React, Helmet } from '@/vendor'
import { AppLayout, Grid, Fs } from '@/components'
import styles from './styles.module.scss'

export default () => (
	<>
		<Helmet>
			<title>CA Transparency Act</title>
		</Helmet>
		<AppLayout
			masthead={<AppLayout.Masthead title="Legal" headline="CA Transparency Act" />}
			bodyClassName={styles.body}
			body={
				<div className={styles.content}>
					<Grid>
						<Grid.Cell>
							<Fs>
								<p>
									Pacific Cheese Co. Inc (the “Company”) provides this statement pursuant to its
									obligations under the California Transparency in Supply Chain Act of 2010 (the
									“Act”). The Act requires the Company to publicly disclose its efforts to eradicate
									slavery and human trafficking in its supply chain. The Act’s purpose is to educate
									the public about human trafficking, empowering consumers through public disclosure
									to make more informed purchasing decisions.
								</p>
								<ul>
									<li>
										Pacific Cheese Co. Inc. is committed to conducting its business in a lawful and
										ethical manner. The Company is strongly opposed to all illegal and unethical
										treatment of people, including any act of slavery or human trafficking.
									</li>
									<li>
										The Company informs all applicable parties of our commitment to the California
										Transparency in Supply Act of 2010 (SB 657).
									</li>
									<li>
										The Company does not engage in the verification of product supply chains to
										evaluate and address risks of human trafficking and slavery. The Company does
										not conduct audits of suppliers to ensure compliance with company standards
										against human trafficking or slavery. The Company does not require suppliers to
										certify that materials incorporated into the product comply with the laws
										regarding human trafficking and slavery of the country or countries in which
										they are conducting business.
									</li>
									<li>
										The Company does not maintain internal accountability standards and procedures
										for employees or contractors failing to meet company standards regarding
										trafficking or slavery.
									</li>
									<li>
										The Company does not provide company employees and management, who have direct
										responsibility of supply chain management, training on human trafficking and
										slavery particularly with respect to mitigating risks within the supply chain of
										product.
									</li>
									<li>The Company does communicate this policy to our employees.</li>
								</ul>
								<p>
									Our current suppliers are primarily located on the western half of United States
									where approximately 95% of our products are sourced. Most remaining products are
									sourced throughout the United States and imported from producers in Western Europe,
									New Zealand and Canada.
								</p>
							</Fs>
						</Grid.Cell>
					</Grid>
				</div>
			}
		/>
	</>
)
