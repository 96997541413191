import { React, Helmet } from '@/vendor'
import { AppLayout, Grid, Fs, Button, FancyImgText, Img, OverlayVideo } from '@/components'
import styles from './styles.module.scss'

const ADP_URL = `https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=7d2ecd90-83e7-4261-a662-411cf4ba1af0&ccId=19000101_000001&type=MP&lang=en_US`

function CareersRoute() {
	const [videoActive, setVideoActive] = React.useState(false)
	return (
		<>
			<Helmet>
				<title>Careers</title>
			</Helmet>
			<AppLayout
				masthead={
					<AppLayout.Masthead
						background="https://d3bcmafqb1amu0.cloudfront.net/mastheads/careers@2x.jpg"
						title="Careers"
						headline="Interested in working with us? We’re handpicking the best"
						body={
							<Button.Link href={ADP_URL} target="_blank">
								View Open Positions
							</Button.Link>
						}
					/>
				}
				body={
					<>
						<FancyImgText>
							<FancyImgText.Row
								image={
									<button
										className={styles['video-button']}
										onClick={() => {
											setVideoActive(true)
										}}
									>
										<Button tag="div" className={styles.icon}>
											<img src={require('./play.png')} alt="play" />
										</Button>
										<Img src="https://d3bcmafqb1amu0.cloudfront.net/careers/great-place-to-work@2x.jpg" />
									</button>
								}
								text={
									<FancyImgText.Text
										headline="A Great Place to Work"
										body={
											<p>
												As a family business for more than 50 years, Pacific Cheese is dedicated
												to providing the highest quality cheese product and service to the
												retail and food service industries. At our heart we are a family
												business and our associates and team members are like family.
											</p>
										}
										cta={
											<Button
												onClick={() => {
													setVideoActive(true)
												}}
											>
												Watch Video
											</Button>
										}
									/>
								}
							/>
							<FancyImgText.Row
								image={
									<Img src="https://d3bcmafqb1amu0.cloudfront.net/careers/perks-and-benefits@2x.jpg" />
								}
								text={
									<FancyImgText.Text
										headline="Perks and Benefits"
										body={
											<p>
												Taking good care of our employees and their families is a top priority
												at Pacific Cheese. We offer competitive compensation packages,
												comprehensive medical coverage options, and merit-based bonuses and
												incentives. At all our facilities, we also take time for fun and
												celebrate with our teams at employee appreciation events and get
												togethers.
											</p>
										}
									/>
								}
							/>
							<FancyImgText.Row
								image={<Img src="https://d3bcmafqb1amu0.cloudfront.net/careers/our-team@2x.jpg" />}
								text={
									<FancyImgText.Text
										headline="Our Team"
										body={
											<p>
												Our team at Pacific Cheese is like family and in that spirit, we focus
												on our employees, their well-being, and their career development.
												Stemming from our Mission Statement, we pride our success on our
												dedicated teams who work together on their common goals and engage with
												our employees to ensure that Pacific Cheese is truly a best place to
												work and thrive.
											</p>
										}
									/>
								}
							/>
							<FancyImgText.Row
								image={
									<Img src="https://d3bcmafqb1amu0.cloudfront.net/careers/our-culture20200211@2x.jpg" />
								}
								text={
									<FancyImgText.Text
										headline="Our Culture"
										body={
											<p>
												With over 50 years’ experience, Pacific Cheese is committed to
												attracting and retaining the most qualified, committed, passionate
												employees who share our values of industry expertise, innovation,
												leadership, and teamwork. As a family business, though we have grown and
												expanded through the years, our culture remains the same, dedicated to
												creating a supportive and welcoming environment for all our employees.
											</p>
										}
									/>
								}
							/>
						</FancyImgText>
						<div className={styles.disclaimer}>
							<Grid>
								<Grid.Cell>
									<Fs variant="caption" align="center">
										Pacific Cheese is an equal opportunity employer and makes employment decisions
										on the basis of merit. Company policy prohibits discrimination based on race,
										color, sex, religion, age, disability, sexual orientation or any other
										consideration prohibited by federal, state or local laws.
									</Fs>
								</Grid.Cell>
							</Grid>
						</div>
					</>
				}
			/>
			<OverlayVideo
				active={videoActive}
				src="https://d3bcmafqb1amu0.cloudfront.net/video/PacificCheeseGreatPlacetoWork_20220525.mp4"
				onClose={() => {
					setVideoActive(false)
				}}
			/>
		</>
	)
}

export default CareersRoute
