import { React, Helmet } from '@/vendor'
import { AppLayout } from '@/components'

class PageNotFoundRoute extends React.PureComponent {
	render() {
		return (
			<>
				<Helmet>
					<title>Page not Found</title>
				</Helmet>
				<AppLayout masthead={<AppLayout.Masthead title="404" headline="Page not Found" />} />
			</>
		)
	}
}

export default PageNotFoundRoute
