import { React, PropTypes } from '@/vendor'
import { pick } from 'lodash'

function ViewportInfo({ children }) {
	const [state, setState] = React.useState({ count: 1 })
	const rAF = React.useRef()
	const compile = () => {
		setState((prev) => ({
			...pick(window, ['scrollY', 'scrollX', 'innerHeight', 'innerWidth'])
		}))
		rAF.current = requestAnimationFrame(compile)
	}
	React.useEffect(() => {
		rAF.current = requestAnimationFrame(compile)
		return () => cancelAnimationFrame(rAF.current)
	}, [])
	return children(state)
}

ViewportInfo.propTypes = {
	children: PropTypes.func.isRequired
}

export default ViewportInfo
