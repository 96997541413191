import { React, Classnames } from '@/vendor'
import Mq from '../Mq'
import ViewportInfo from '../ViewportInfo'
import Grid from '../Grid'
import Fs from '../Fs'
import styles from './styles.module.scss'

function FancyImgText({ className, children }) {
	return (
		<ViewportInfo>
			{(viewport) => (
				<Mq>
					{(Mq) =>
						React.Children.map(children, (child, idx) => {
							const odd = idx % 2 !== 0
							const stacked = Mq.small
							return (
								<div className={styles.outerRow}>
									{React.cloneElement(child, {
										idx,
										stacked,
										reverse: !stacked && odd,
										viewport
									})}
								</div>
							)
						})
					}
				</Mq>
			)}
		</ViewportInfo>
	)
}

FancyImgText.Row = ({ image, text, stacked, reverse, viewport, idx }) => {
	const el = React.useRef()
	let offset = null
	if (!stacked && el.current) {
		const bounds = el.current.$el.getBoundingClientRect()
		offset = bounds.y * 0.15
	}
	return (
		<Grid reverse={reverse} stacked={stacked} ref={el}>
			<Grid.Cell className={styles.row__primary} flex={stacked ? null : 5}>
				{image}
			</Grid.Cell>
			<Grid.Cell className={styles.row__secondary}>
				{React.cloneElement(text, {
					offset,
					floatLeft: reverse && !stacked,
					floatRight: !reverse && !stacked
				})}
			</Grid.Cell>
		</Grid>
	)
}

FancyImgText.Text = ({ headline, body, cta, floatLeft, floatRight, offset }) => {
	const el = React.useRef()
	const className = Classnames(styles.text, {
		[styles.floatLeft]: floatLeft,
		[styles.floatRight]: floatRight
	})
	const style = offset
		? {
				transform: `translateY(${offset}px)`
		  }
		: null
	return (
		<div className={className} ref={el} style={style}>
			<Fs tag="h2" variant="fs2">
				{headline}
			</Fs>
			<Fs variant="body">{body}</Fs>
			{cta && <div className={styles.cta}>{cta}</div>}
		</div>
	)
}

export default FancyImgText
