import { React, PropTypes } from '@/vendor'

class Img extends React.PureComponent {
	static propTypes = {
		src: PropTypes.string
	}

	state = {
		ready: false,
		width: undefined,
		height: undefined
	}

	constructor(props) {
		super(props)
		const faux = new Image()
		faux.onload = () => {
			const { naturalWidth, naturalHeight } = faux
			if (this.mounted) {
				this.setState({
					ready: true,
					width: Math.round(naturalWidth / 2),
					height: Math.round(naturalHeight / 2)
				})
			}
		}
		faux.src = props.src
	}

	componentDidMount() {
		this.mounted = true
	}

	componentWillUnmount() {
		this.mounted = false
	}

	render() {
		const { src, ...props } = this.props
		return <img {...props} src={src} width={this.state.width} height={this.state.height} />
	}
}

export default Img
