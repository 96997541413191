import { React, PropTypes, Classnames, Link } from '@/vendor'
import Fs from '../../Fs'
import Mq from '../../Mq'
import Craft from '../../Craft'
import Grid from '../../Grid'
import Burger from './Burger'
import PageChangeListener from '../../PageChangeListener'
import OverlayNav from './OverlayNav'
import { NavItems, Logo } from '../_internals'
import styles from './styles.module.scss'

class Masthead extends React.PureComponent {
	static defaultProps = {
		style: {}
	}

	static propTypes = {
		className: PropTypes.string,
		background: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
		title: PropTypes.string,
		headline: PropTypes.node,
		body: PropTypes.node,
		style: PropTypes.object,
		render: PropTypes.func
	}

	state = {
		overlayNavActive: false,
		servicesHover: false
	}

	get backgroundImageStyle() {
		if (typeof this.props.background === 'string') {
			return {
				backgroundImage: `url('${this.props.background}')`
			}
		}
		return null
	}

	render() {
		return (
			<Craft>
				{({ services }) => {
					return (
						<Mq>
							{(Mq) => {
								const classNames = Classnames(this.props.className, styles.masthead, {
									[styles.hasBg]: !!this.props.background
								})
								return (
									<>
										<PageChangeListener
											callback={() => {
												this.setState({
													overlayNavActive: false,
													servicesHover: false
												})
											}}
										/>
										<div
											className={classNames}
											style={{
												...this.props.style,
												...this.backgroundImageStyle
											}}
										>
											{React.isValidElement(this.props.background) && (
												<div className={styles.injectedBackground}>{this.props.background}</div>
											)}
											<div className={styles.header}>
												{Mq.large ? (
													<ul className={styles.nav}>
														<li>
															<NavItems.WhoWeAre />
														</li>
														<li
															onMouseEnter={() => {
																this.setState({
																	servicesHover: true
																})
															}}
															onMouseLeave={() => {
																this.setState({
																	servicesHover: false
																})
															}}
														>
															<NavItems.Services />
															<ul
																className={Classnames({
																	[styles.active]: this.state.servicesHover
																})}
															>
																{services.map((entry) => (
																	<li key={entry.slug}>
																		<Link to={`/services/${entry.slug}`}>
																			{entry.title}
																		</Link>
																	</li>
																))}
															</ul>
														</li>
														<li>
															<NavItems.Cheeses />
														</li>
														<li className={styles.logo}>
															<Logo />
														</li>
														<li>
															<NavItems.Resources />
														</li>
														<li>
															<NavItems.Careers />
														</li>
														<li>
															<NavItems.HowToBuy />
														</li>
													</ul>
												) : (
													<div className={styles.mobileHeader}>
														<Logo className={styles.logo} />
														<Burger
															className={styles.burger}
															active={this.state.overlayNavActive}
															onClick={() => {
																this.setState({
																	overlayNavActive: !this.state.overlayNavActive
																})
															}}
														/>
													</div>
												)}
											</div>
											{this.props.render ? (
												this.props.render()
											) : (
												<Grid>
													<Grid.Cell>
														<div className={styles.headline}>
															{this.props.title && (
																<Fs variant="fs6" className={styles.title}>
																	{this.props.title}
																</Fs>
															)}
															{this.props.headline &&
																(typeof this.props.headline === 'string' ? (
																	<Fs variant="h1" tag="h1">
																		{this.props.headline}
																	</Fs>
																) : (
																	this.props.headline
																))}
															{this.props.body && (
																<div className={styles.body}>
																	<Fs>{this.props.body}</Fs>
																</div>
															)}
														</div>
													</Grid.Cell>
												</Grid>
											)}
											{!Mq.large && this.state.overlayNavActive && (
												<OverlayNav>
													<NavItems.WhoWeAre />
													<>
														<NavItems.Services />
														<ul>
															{services.map((entry) => (
																<li key={entry.slug}>
																	<Link to={`/services/${entry.slug}`}>
																		{entry.title}
																	</Link>
																</li>
															))}
														</ul>
													</>
													<NavItems.Cheeses />
													<NavItems.Resources />
													<NavItems.Careers />
													<NavItems.HowToBuy />
													<NavItems.Contact />
												</OverlayNav>
											)}
										</div>
									</>
								)
							}}
						</Mq>
					)
				}}
			</Craft>
		)
	}
}

export default Masthead
