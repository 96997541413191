import { React, Helmet } from '@/vendor'
import { AppLayout, Grid, Fs, Button, FancyImgText, Img, LogoBar, Intro, OverlayVideo } from '@/components'
import styles from './styles.module.scss'

function HomepageRoute() {
	const [videoActive, setVideoActive] = React.useState(false)
	return (
		<>
			<Helmet titleTemplate="">
				<title>Welcome to Pacific Cheese</title>
			</Helmet>
			<AppLayout
				masthead={
					<AppLayout.Masthead
						background={
							<video autoPlay muted loop playsInline>
								<source
									src="https://d3bcmafqb1amu0.cloudfront.net/video/home_bg.mp4"
									type="video/mp4"
								/>
							</video>
						}
						headline={
							<Fs tag="h1" variant="mega">
								Naturally
								<br />
								Good Cheese
							</Fs>
						}
						body={
							<div className={styles.masthead__body}>
								<p>
									Pacific Cheese supplies the highest quality service and cheese products to retail
									and foodservice industries around the world.
								</p>
								<Button
									onClick={() => {
										setVideoActive(true)
									}}
								>
									Watch Video
								</Button>
							</div>
						}
					/>
				}
				bodyClassName={styles.body}
				body={
					<>
						<LogoBar ids={[91, 109, 112, 95, 106]} />
						<div className={styles.section}>
							<FancyImgText>
								<FancyImgText.Row
									image={
										<Img src="https://d3bcmafqb1amu0.cloudfront.net/homepage/celebrating_50_years@2x.jpg" />
									}
									text={
										<FancyImgText.Text
											headline="Celebrating 50 Years"
											body={
												<p>
													For over 50 years, Pacific Cheese has remained an industry leading
													broad-line cheese supplier, and as a growing family business, we
													continue our commitment to deliver the highest quality cheeses to
													customers around the world. From popular private labels to store
													brands, we’re dedicated to our customer’s success.
												</p>
											}
											cta={<Button.Link to="/cheeses">Our Cheese</Button.Link>}
										/>
									}
								/>
								<FancyImgText.Row
									image={
										<Img src="https://d3bcmafqb1amu0.cloudfront.net/homepage/join-the-team@2x.jpg" />
									}
									text={
										<FancyImgText.Text
											headline="Careers at Pacific Cheese"
											body={
												<p>
													Pacific Cheese is home to some of the most committed team players in
													the business! Search our job opportunities and find out why Pacific
													Cheese is a great place to work.
												</p>
											}
											cta={<Button.Link to="/careers">Join the Team</Button.Link>}
										/>
									}
								/>
							</FancyImgText>
						</div>
						<div className={`${styles.section} ${styles.map}`}>
							<Grid>
								<Grid.Cell className={styles.map__cell}>
									<div className={styles.map__inner}>
										<Intro
											preHeadline="A Global Reach"
											headline="Shaping the future of distribution with innovative packaging and products."
											html={<Button.Link to="/services">Our Services</Button.Link>}
										/>
									</div>
								</Grid.Cell>
							</Grid>
						</div>
					</>
				}
			/>
			<OverlayVideo
				active={videoActive}
				src="https://d3bcmafqb1amu0.cloudfront.net/video/pac_cheese_final_20200120.mp4"
				onClose={() => {
					setVideoActive(false)
				}}
			/>
		</>
	)
}

export default HomepageRoute
