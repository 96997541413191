import React from 'react'
import styles from './styles.module.scss'

function Spinner() {
	return (
		<div className={styles.spinner}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}

export default Spinner
