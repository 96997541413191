import { React, PropTypes, Helmet } from '@/vendor'
import { Craft, AppLayout, Grid, Img, RichText, Button } from '@/components'
import { useParams, Navigate } from 'react-router-dom'
import styles from './styles.module.scss'

class ResourceDetail extends React.PureComponent {
	static propTypes = {
		entry: PropTypes.shape({
			title: PropTypes.string,
			leadImage: PropTypes.string,
			articleMatrix: PropTypes.arrayOf(
				PropTypes.shape({
					richText: PropTypes.string
				})
			)
		})
	}

	render() {
		const { title, leadImage, articleMatrix } = this.props.entry
		return (
			<>
				<Helmet>
					<title>{title}</title>
				</Helmet>
				<AppLayout
					masthead={<AppLayout.Masthead title="Resources" headline={title} />}
					bodyClassName={styles.body}
					body={
						<>
							<Grid>
								<Grid.Cell>
									<Img src={leadImage} />
									<div className={styles.article}>
										{articleMatrix.map((content, idx) => (
											<RichText key={idx}>{content.richText}</RichText>
										))}
									</div>
								</Grid.Cell>
							</Grid>
							<div className={styles.back}>
								<Button.Link to="/resources">View All Resources</Button.Link>
							</div>
						</>
					}
				/>
			</>
		)
	}
}

export default () => {
	const { slug } = useParams()
	return (
		<Craft>
			{({ resources }) => {
				const resource = resources.find((r) => r.slug === slug)
				return resource ? <ResourceDetail entry={resource} /> : <Navigate to="/resources" />
			}}
		</Craft>
	)
}
