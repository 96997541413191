import { React, PropTypes, Classnames } from '@/vendor'
import styles from './styles.module.scss'

const Context = React.createContext({})

export function InlineNav({ children, initial, onChange }) {
	const [active, setActive] = React.useState(initial)
	return (
		<Context.Provider value={{ active, setActive, onChange }}>
			<ul className={styles.inlineNav}>{children}</ul>
		</Context.Provider>
	)
}

InlineNav.propTypes = {
	children: PropTypes.node.isRequired,
	initial: PropTypes.string,
	onChange: PropTypes.func.isRequired
}

export function InlineNavItem({ value, label }) {
	const { active, setActive, onChange } = React.useContext(Context)
	return (
		<li
			onClick={() => {
				setActive(value)
				onChange(value)
			}}
			className={Classnames({ [styles.active]: active === value })}
		>
			<div className={styles.label}>{label}</div>
		</li>
	)
}

InlineNavItem.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string.isRequired
}
