import { React, Helmet, Link } from '@/vendor'
import { Craft, AppLayout, FancyImgText, Button, Img, LogoBar } from '@/components'

class ServicesRoute extends React.PureComponent {
	render() {
		return (
			<Craft>
				{({ services }) => (
					<>
						<Helmet>
							<title>Services</title>
						</Helmet>
						<AppLayout
							masthead={
								<AppLayout.Masthead
									title="Services"
									headline="We’re Best in Class"
									body={
										<>
											<p>
												Pacific Cheese delivers an exceptional variety of cheese at-the-ready
												for foodservice and retail environments. We’re experts at handling
												private-labels, and also offer our own beloved brands: Cheswick, North
												Beach, Pacific Blue, and California Select Farms.
											</p>
											<LogoBar ids={[91, 109, 112, 95, 106, 589, 595]} />
										</>
									}
								/>
							}
							body={
								<FancyImgText>
									{services.map((service) => {
										const url = `/services/${service.slug}`
										return (
											<FancyImgText.Row
												key={service.id}
												image={
													<Link to={url}>
														<Img src={service.leadImage[0].url} />
													</Link>
												}
												text={
													<FancyImgText.Text
														headline={service.title}
														body={
															<div
																dangerouslySetInnerHTML={{
																	__html: service.intro
																}}
															></div>
														}
														cta={<Button.Link to={url}>View Service</Button.Link>}
													/>
												}
											/>
										)
									})}
								</FancyImgText>
							}
						/>
					</>
				)}
			</Craft>
		)
	}
}

export default ServicesRoute
