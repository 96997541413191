import { React, PropTypes, Classnames } from '@/vendor'
import Mq from '../Mq'
import Grid from '../Grid'
import Fs from '../Fs'
import styles from './styles.module.scss'

function SimpleImgText({ className, alternate, children }) {
	return (
		<Mq>
			{(Mq) => (
				<div className={styles.wrapper}>
					{React.Children.map(children, (child, idx) => {
						const flipped = idx % 2 === 0
						const stacked = Mq.small
						return React.cloneElement(child, {
							stacked,
							reverse: alternate && !stacked && flipped
						})
					})}
				</div>
			)}
		</Mq>
	)
}

SimpleImgText.defaultProps = {
	alternate: false
}

SimpleImgText.propTypes = {
	className: PropTypes.string,
	alternate: PropTypes.bool,
	children: PropTypes.node
}

SimpleImgText.Row = ({ image, text, stacked, reverse, className, textClassName }) => (
	<Grid reverse={reverse} stacked={stacked} className={Classnames(className, styles.row)}>
		<Grid.Cell className={styles.row__primary}>{image}</Grid.Cell>
		<Grid.Cell className={Classnames(textClassName, styles.row__secondary)}>{text}</Grid.Cell>
	</Grid>
)

SimpleImgText.Text = ({ headline, meta, body, cta }) => {
	return (
		<>
			<Fs tag="h2" variant="fs2">
				{headline}
			</Fs>
			{meta && <Fs variant="caption">{meta}</Fs>}
			<Fs variant="body">{body}</Fs>
			{cta}
		</>
	)
}

export default SimpleImgText
