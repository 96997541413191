import { React, Helmet } from '@/vendor'
import {
	Craft,
	Mq,
	AppLayout,
	Grid,
	Fs,
	TinySlider,
	SimpleImgText,
	Img,
	RichText,
	Button,
	FancyImgText,
	HorzRule,
	Intro,
	LogoBar
} from '@/components'
import Velocity from 'velocity-animate'
import styles from './styles.module.scss'

const slides = [
	{
		title: 'Pacific Cheese',
		navLabel: 'All',
		bgColor: '#C0C0B8',
		imageUrl: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/pacific-cheese@2x.jpg',
		body: (
			<p>
				We offer a complete range of fresh and soft-ripened cheeses, blue-veined cheeses, pasta filata, and
				semi-hard & hard cheeses.
			</p>
		)
	},
	{
		title: 'Fresh Cheese',
		navLabel: 'Fresh',
		bgColor: '#BAADA7',
		imageUrl: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/fresh-cheese@2x.jpg',
		body: (
			<p>
				The youngest and purest form of all cheeses. Pacific cheese produces these silky cheeses for spreadable
				enjoyment.
			</p>
		)
	},
	{
		title: 'Soft Cheese',
		navLabel: 'Soft',
		bgColor: '#F4E8D9',
		imageUrl: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/soft-cheese@2x.jpg',
		body: (
			<p>
				We produce high quality soft-ripened and semi-soft cheeses for the perfect accents to a dish or as the
				star of the show.
			</p>
		)
	},
	{
		title: 'Hard Cheese',
		navLabel: 'Hard',
		bgColor: '#D8DFE2',
		imageUrl: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/hard-cheese@2x.jpg',
		body: (
			<p>
				Pacific Cheese produces top-quality savory cheeses at a great value. These firmer all-purpose cheeses
				are pressed to perfection.
			</p>
		)
	},
	{
		title: 'Blue Cheese',
		navLabel: 'Blue',
		bgColor: '#D9E2D8',
		imageUrl: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/blue-cheese@2x.jpg',
		body: (
			<p>
				Made with pasteurized milk, Pacific Cheese’s Blue Cheese is beautifully marbled with memorable flavor.
			</p>
		)
	},
	{
		title: 'Pasta Filata Cheese',
		navLabel: 'Pasta Filata',
		bgColor: '#DDD4CB',
		imageUrl: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/pasta-filata@2x.jpg',
		body: (
			<p>
				Pacific Cheese also produces one of the most popular cheeses of all, Pasta Filata. This Italian cheese
				will add the perfect texture to any pizza.
			</p>
		)
	}
]

const learnMore = [
	{
		image: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/fresh_icon@2x.jpg',
		title: 'Fresh',
		body: (
			<p>
				Ricotta, fresh mozzarella and cream cheese are included in this category. Typically, these cheeses have
				a fresh, creamy, milky flavor and, are usually high in moisture content.
			</p>
		)
	},
	{
		image: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/soft-ripened_icon@2x.jpg',
		title: 'Soft-Ripened',
		body: (
			<p>
				Brie and Camembert are soft-ripened cheese varieties, which typically have a soft-body and bloomy white
				mold on the rind that ripens the cheese from the outside in.
			</p>
		)
	},
	{
		image: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/semi-soft_icon@2x.jpg',
		title: 'Semi-Soft',
		body: (
			<p>
				Monterey Jack, Havarti and Fontina, among others, are in the semi-soft category – a popular group of
				cheeses that melt well when cooked.
			</p>
		)
	},
	{
		image: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/firm-hard_icon@2x.jpg',
		title: 'Firm/Hard',
		body: (
			<p>
				This category ranges from semi-firm Cheddar, Colby and Edam, to hard cheeses that are aged to perfection
				for years in some cases.
			</p>
		)
	},
	{
		image: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/blue_icon@2x.jpg',
		title: 'Blue',
		body: (
			<p>
				Flavorful and pungent, blue-veined cheeses include traditional blue cheese, Gorgonzola and Roquefort,
				and feature blue spots or blue veins through the interior of the cheese.
			</p>
		)
	},
	{
		image: 'https://d3bcmafqb1amu0.cloudfront.net/cheeses/pasta-filata_icon@2x.jpg',
		title: 'Pasta Filata',
		body: (
			<p>
				Pasta Filata means “spun paste” in Italian, and includes Mozzarella and Provolone cheeses. The curds are
				heated and stretched, resulting in a cheese that stretches when it melts.
			</p>
		)
	}
]

class CheesesRoute extends React.PureComponent {
	state = {
		mastheadBackgroundColor: slides[0].bgColor
	}

	learnMoreHandler = () => {
		Velocity(document.body, 'scroll', {
			duration: 300,
			offset: this.$learnMore.offsetTop,
			easing: 'easeInOutExpo'
		})
	}

	render() {
		return (
			<Craft>
				{() => (
					<Mq>
						{(Mq) => (
							<>
								<Helmet>
									<title>Cheeses</title>
								</Helmet>
								<AppLayout
									masthead={
										<AppLayout.Masthead
											className={styles.masthead}
											style={{
												backgroundColor: this.state.mastheadBackgroundColor
											}}
											render={() => {
												const setBg = (slider) => {
													this.setState({
														mastheadBackgroundColor: slides[slider.index].bgColor
													})
												}
												return !Mq.small ? (
													<>
														<TinySlider
															options={{
																autoHeight: true,
																autoplay: false,
																loop: false,
																navContainer: '#sliderNav'
															}}
															ref={(el) => (this.$slider = el)}
															events={{
																transitionStart: setBg
															}}
														>
															{slides.map((slide, idx) => (
																<TinySlider.Slide key={idx}>
																	<div className={styles.masthead__slide}>
																		<SimpleImgText alternate>
																			<SimpleImgText.Row
																				image={<Img src={slide.imageUrl} />}
																				text={
																					<SimpleImgText.Text
																						headline={slide.title}
																						body={
																							<>
																								<RichText>
																									{slide.body}
																								</RichText>
																								<Button
																									onClick={
																										this
																											.learnMoreHandler
																									}
																									className={
																										styles.masthead__cta
																									}
																								>
																									Learn More
																								</Button>
																							</>
																						}
																					/>
																				}
																				textClassName={
																					styles.masthead__simpleImgTextBody
																				}
																			/>
																		</SimpleImgText>
																	</div>
																</TinySlider.Slide>
															))}
														</TinySlider>
														<ul id="sliderNav" className={styles.sliderNav}>
															{slides.map((slide, idx) => (
																<li key={idx}>
																	<div className={styles.sliderNav__label}>
																		{slide.navLabel}
																	</div>
																</li>
															))}
														</ul>
													</>
												) : (
													<SimpleImgText alternate>
														<SimpleImgText.Row
															image={
																<SimpleImgText.Text
																	headline={slides[0].title}
																	body={<RichText>{slides[0].body}</RichText>}
																/>
															}
															text={<Img src={slides[0].imageUrl} />}
														/>
													</SimpleImgText>
												)
											}}
										/>
									}
									body={
										<>
											<FancyImgText>
												<FancyImgText.Row
													image={
														<Img src="https://d3bcmafqb1amu0.cloudfront.net/cheeses/world_of_cheese@2x.jpg" />
													}
													text={
														<FancyImgText.Text
															headline="World of Cheese"
															body={
																<p>
																	We offer a complete range of cheeses in a variety of
																	cut styles. In addition to conventionally prepared
																	cheeses, we offer organic, kosher, and rBST-free
																	cheese (made with milk free of added growth
																	hormones). And with our partnerships with some of
																	the world’s finest cheese producers, Pacific Cheese
																	offers our customers the widest range of quality
																	cheese products in the industry.
																</p>
															}
														/>
													}
												/>
												<FancyImgText.Row
													image={
														<Img src="https://d3bcmafqb1amu0.cloudfront.net/cheeses/trusted_partner@2x.jpg" />
													}
													text={
														<FancyImgText.Text
															headline="Trusted Partner"
															body={
																<>
																	<p>
																		Pacific Cheese's product line includes natural
																		and processed commodity cheeses, specialty and
																		artisan varieties, international imports, and
																		popular Hispanic style cheeses.
																	</p>
																	<p>
																		In addition to conventionally prepared cheeses,
																		we offer organic, kosher, and rBST-free cheese
																		(made with milk free of added growth hormones).
																	</p>
																</>
															}
														/>
													}
												/>
											</FancyImgText>
											<div
												className={styles.learnMore}
												ref={(el) => {
													this.$learnMore = el
												}}
											>
												<Grid className={styles.learnMore__grid} wrap>
													{learnMore.map((item, idx) => (
														<Grid.Cell className={styles.learnMore__cell} key={idx}>
															<Img
																src={item.image}
																alt={item.title}
																className={styles.learnMore__img}
															/>
															<Fs align="center">
																<Fs tag="h3" variant="fs3">
																	{item.title}
																</Fs>
																{item.body}
															</Fs>
														</Grid.Cell>
													))}
												</Grid>
											</div>
											<HorzRule />
											<Grid>
												<Grid.Cell>
													<Intro
														preHeadline="Our Additional Brands"
														headline="We are Experts at Handling Private Labels"
														html={
															<>
																<p>
																	When it comes to private labels and store brands,
																	Pacific Cheese has focused for many years on
																	private-label production and has the industry
																	expertise to help you get your product to market.
																	Our industry partners are positioned for success,
																	with our exceptional custom branding, volume
																	production, and global distribution.
																</p>
																<LogoBar ids={[91, 109, 112, 95, 106]} />
															</>
														}
													/>
												</Grid.Cell>
											</Grid>
										</>
									}
								/>
							</>
						)}
					</Mq>
				)}
			</Craft>
		)
	}
}

export default CheesesRoute
