import { React, Classnames } from '@/vendor'
import { VideoPlayer, SvgNotch, CSSTransition } from '@/components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import styles from './styles.module.scss'

class OverlayVideo extends React.PureComponent {
	componentDidMount() {
		disableBodyScroll(this.$el)
	}

	componentWillUnmount() {
		enableBodyScroll(this.$el)
	}

	render() {
		const { className, onClose, src, active, ...props } = this.props
		return (
			<CSSTransition
				in={active}
				classNames={{
					enter: styles.enter,
					enterActive: styles.enterActive,
					exitActive: styles.exitActive
				}}
				children={
					<div
						className={Classnames(styles.overlayVideo, className)}
						{...props}
						ref={(el) => {
							this.$el = el
						}}
					>
						<VideoPlayer autoPlay={true} controls className={styles.video}>
							<source src={src} type="video/mp4" />
						</VideoPlayer>
						<button onClick={onClose} className={styles.close}>
							<SvgNotch />
							Return
						</button>
					</div>
				}
			/>
		)
	}
}

export default OverlayVideo
