import { React, PropTypes, Classnames, NavLink } from '@/vendor'
import { Craft, Img, Fs, Grid, Mq } from '@/components'
import styles from './styles.module.scss'
import Masthead from './Masthead'

function NavGroup({ className, title, render, children }) {
	return (
		<div className={Classnames(styles.navGroup, className)}>
			<h5 className={styles.navGroup__header}>{title}</h5>
			{render ? (
				render
			) : (
				<ul className={styles.navGroup__list}>
					{React.Children.map(children, (child, idx) => (
						<li key={idx}>{child}</li>
					))}
				</ul>
			)}
		</div>
	)
}
NavGroup.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	render: PropTypes.node,
	children: PropTypes.node
}

class AppLayout extends React.PureComponent {
	static Masthead = Masthead
	static propTypes = {
		masthead: PropTypes.node,
		bodyClassName: PropTypes.string,
		body: PropTypes.node
	}
	render() {
		return (
			<Craft>
				{({ services }) => (
					<Mq>
						{(Mq) => {
							const showFiftyYear = true
							const showCaliMilk = Mq.large
							const stackedFooterNavGroups = Mq.small
							return (
								<div className={styles.wrapper}>
									<div className={styles.wrapper__upper}>
										{this.props.masthead}
										<div className={Classnames(styles.wrapper__body, this.props.bodyClassName)}>
											{this.props.body}
										</div>
									</div>
									<div className={styles.wrapper__lower}>
										{showFiftyYear && (
											<div className={styles.lower__50year}>
												<Grid>
													<Grid.Cell>
														<Img
															src={require('./50year@2x.png')}
															alt="50 year anniversary"
															className={styles.lower__50year_img}
														/>
														<Fs variant="fs3">Your trusted Partner for 50 years</Fs>
													</Grid.Cell>
												</Grid>
											</div>
										)}
										<div className={styles.lower__footer}>
											<Grid>
												{showCaliMilk && (
													<Grid.Cell>
														<Img
															src={require('./real_cali_milk@2x.png')}
															alt="Real California Milk"
															className={styles.caliMilk}
														/>
													</Grid.Cell>
												)}
												<Grid.Cell flex={showCaliMilk ? 3 : null}>
													<Grid stacked={stackedFooterNavGroups}>
														<Grid.Cell>
															<NavGroup title="Company">
																<NavLink to="/who-we-are">Who We Are</NavLink>
																<NavLink to="/resources">Resources</NavLink>
																<NavLink to="/careers">Careers</NavLink>
															</NavGroup>
														</Grid.Cell>
														<Grid.Cell>
															<NavGroup title="Services">
																{services.map((entry) => (
																	<NavLink
																		to={`/services/${entry.slug}`}
																		key={entry.slug}
																	>
																		{entry.title}
																	</NavLink>
																))}
															</NavGroup>
														</Grid.Cell>
														<Grid.Cell>
															<NavGroup title="Support">
																<NavLink to="/how-to-buy">How to Buy</NavLink>
																<NavLink to="/contact">Contact</NavLink>
															</NavGroup>
														</Grid.Cell>
														<Grid.Cell>
															<NavGroup
																title="Follow"
																render={
																	<div className={styles.socialLinks}>
																		<a
																			href="https://www.facebook.com/PacificCheese"
																			target="_blank"
																		>
																			<Img
																				src="https://d3bcmafqb1amu0.cloudfront.net/social/facebook@2x.png"
																				alt="Facebook"
																			/>
																		</a>
																		<a
																			href="https://www.linkedin.com/company/pacific-cheese-co."
																			target="_blank"
																		>
																			<Img
																				src="https://d3bcmafqb1amu0.cloudfront.net/social/linkedin@2x.png"
																				alt="LinkedIn"
																			/>
																		</a>
																		<a
																			href="https://twitter.com/pacificcheese"
																			target="_blank"
																		>
																			<Img
																				src="https://d3bcmafqb1amu0.cloudfront.net/social/twitter@2x.png"
																				alt="Twitter"
																			/>
																		</a>
																	</div>
																}
															/>
														</Grid.Cell>
													</Grid>
												</Grid.Cell>
											</Grid>
											<div className={styles.copyright}>
												<Grid>
													<Grid.Cell>
														© {new Date().getFullYear()} Pacific Cheese, Co{' '}
														{stackedFooterNavGroups ? <br /> : <>&nbsp;</>} All Rights
														Reserved &nbsp;|&nbsp;{' '}
														<NavLink to="/transparency-act">CA Transparency Act</NavLink>{' '}
														&nbsp;|&nbsp; Customer Service at 800.499.6200
													</Grid.Cell>
												</Grid>
											</div>
										</div>
									</div>
								</div>
							)
						}}
					</Mq>
				)}
			</Craft>
		)
	}
}

export default AppLayout
